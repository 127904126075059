<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    authority="Orders_Order"
    single-edit
    enable-disable-toggle
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../ordersStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      orderStatusOptions: [],
      customerOptions: [],
      deliveryMethodOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'orders',
        endpoint: 'orders',
        route: 'orders',
        title: {
          single: this.$i18n.t('Order'),
          plural: this.$i18n.t('Orders'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'orderNumber', label: this.$i18n.t('number'), sortable: true },
        {
          key: 'orderStatusTitle',
          label: this.$i18n.t('Status'),
          sortable: false,
        },
        {
          key: 'creationDate',
          label: this.$i18n.t('Creation Date'),
          sortable: true,
        },
        {
          key: 'deliveryMethodTitle',
          label: this.$i18n.t('Delivery Method'),
          sortable: false,
        },
        { key: 'totalPrice', label: this.$i18n.t('price') },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'totalPrice', type: 'price' },
        { key: 'creationDate', type: 'datetime' },
      ]
    },
    filters() {
      return [
        {
          name: 'offer',
          type: 'boolean',
          label: this.$i18n.t('offer'),
          value: null,
        },
        {
          name: 'orderstatus',
          label: this.$t('Order Status'),
          options: this.orderStatusOptions,
          value: null,
        },
        {
          name: 'customer',
          label: this.$t('Customer Filter'),
          options: this.customerOptions,
          value: null,
        },
        {
          name: 'deliverymethod',
          label: this.$t('Delivery Method'),
          options: this.deliveryMethodOptions,
          value: null,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '5',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '4',
          maxDate: new Date(),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCustomers').then((response) => {
      this.customerOptions = response.data
    })
    this.$store.dispatch('app-common/fetchDeliveryMethods').then((response) => {
      this.deliveryMethodOptions = response.data
    })
    this.$store.dispatch('app-common/fetchOrderStatuses').then((response) => {
      this.orderStatusOptions = response.data
    })
  },
}
</script>
